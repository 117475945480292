<template>
  <div class="my-container">
    <!-- 导航栏 -->
    <nav-bar :title="registe" :backColor="'#ff0000'"></nav-bar>
    <!-- 注册表单 -->
    <div class="kong"></div>
    <div class="new-form">
      <div class="form-item" v-for="(item, index) in formList" :key="index">
        <span>{{ item.label }}</span>
        <input
          :type="item.type"
          :placeholder="item.placeholder"
          v-model="user[item.key]"
        />
        <p class="num-absolute" v-if="!index">+84</p>
        <div v-if="item.key === 'code'" class="send-code-com">
          <van-count-down
            :time="1000 * 60"
            format="nhận lại ss thứ hai"
            v-if="isCountDownShow"
            @finish="isCountDownShow = false"
          />
          <p v-else @click="onSendSms">{{ registeinfo }}</p>
        </div>
      </div>
      <!-- <div class="checkbox">
        <van-checkbox
          v-model="checked"
          shape="square"
          checked-color="#DC1323"
          icon-size="20px"
        />
        <p>tôi chấp nhận<span @click="$router.push('agreement')">&lt;&lt;Thỏa thuận người dùng và Chính sách quyền riêng tư&gt;&gt;</span></p>
      </div> -->
      <div class="btn-box">
        <button @click="$router.back()">quay lại đăng nhập</button>
        <button @click="onSubmit">Reset mật khẩu mở khóa</button>
      </div>
    </div>

    <!-- <div class="registe">
      <div class="goLogin">
        <span @click="$router.back()">quay lại đăng nhập</span>
      </div>
    </div> -->
  </div>
</template>

<script>
// 导入导航栏组件
import Navbar from '@/components/Navbar.vue'
import '../my.less'
import { sendSms, login, forgetPwd } from '@/api/use.js'
// import request from '@/utils/request'

export default {
  name: '',
  components: {
    'nav-bar': Navbar
  },
  data () {
    return {
      registe: 'sự lôi kéomật khẩu mở khóa',
      user: {
        userphone: '',
        code: '',
        userpass: ''
      },
      checked: true,
      //  点击信息的提示
      registeinfo: 'Bắt mã xác nhận',
      // 控制是否显示倒计时
      isCountDownShow: false,
      formList: [
        {
          label: 'số điện thoại',
          type: 'number',
          placeholder: 'Xin vui lòng điền số điện thoại của bạn.!',
          key: 'userphone'
        },
        {
          label: 'kiểm tra mã',
          type: 'number',
          placeholder: 'vui lòng nhập mã xác nhận',
          key: 'code'
        }
        // {
        //   label: 'Mã giới thiệu',
        //   type: 'password',
        //   placeholder: 'đặt lại mật khẩu đăng nhập',
        //   key: 'userpass',
        // },
      ]
    }
  },
  mounted () {},
  created () {
    if (this.$route.query.id) {
      localStorage.setItem('fromid', this.$route.query.id)
    }
  },
  methods: {
    // 1、点击获取短信事件
    async onSendSms () {
      // 调用发送验证码请求的异步请求
      if (this.user.userphone) {
        // 如果số điện thoại验证成功，让倒计时显示
        this.isCountDownShow = true
        try {
          const { data } = await sendSms({
            userphone: this.user.userphone,
            must: 1
          })
          data.ret === 1
            ? this.$toast('Mã xác minh đã được nhận.')
            : this.$toast(
              'Đã gửi thành công, chỉ có thể gửi một lần trong vòng 60 giây.'
            )
        } catch (error) {
          this.isCountDownShow = false
        }
      } else this.$toast('Xin vui lòng nhập một số điện thoại hợp lệ.')
    },
    // 2、定义注册的事件
    async onSubmit (e) {
      e.stopPropagation()
      if (this.checked) {
        if (this.user.userphone || this.user.userpass || this.user.code) {
          const parame = {
            userphone: this.user.userphone,
            code: this.user.code,
            userpass: this.user.userpass
          }
          if (this.$route.query.id || localStorage.getItem('fromid')) {
            parame.fromid =
              this.$route.query.id || localStorage.getItem('fromid')
          }
          const { data } = await forgetPwd({
            ...parame
          })
          if (data.ret === 1) {
            this.$toast(
              'Xin chúc mừng đã khôi phục thành công khóa mở mật khẩu.'
            )
            this.onlogin()
            localStorage.removeItem('fromid')
          } else {
            this.$toast.fail(data.msg)
          }
        } else this.$toast('Xin vui lòng nhập một số điện thoại hợp lệ.')
      } else this.$toast('bạn phải đồng ý')
    },

    // 3、注册成功直接登录
    async onlogin () {
      try {
        const {
          data: { ret, data }
        } = await login({
          userphone: this.user.userphone,
          userpass: this.user.userpass
        })
        if (ret === 1) {
          this.$store.commit('setUser', data.token)
          this.$toast.success('đăng nhập thành công')
          localStorage.removeItem('fromid')
          this.$router.push('/')
        } else {
          this.$toast.fail(data.msg)
        }
      } catch (e) {}
    }
  }
}
</script>

<style lang="less" scoped>
@toast-position-bottom-distance:10% !;
.goLogin {
  margin-top: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  span {
    font-size: 26px;
  }
}
.registe {
  .van-field {
    margin-top: 18px;
  }
  .send-sms-btn {
    width: 152px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    font-size: 22px;
    color: rgb(0, 76, 254);
    border: none;
  }
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 50px 0 18px 50px;
  .van-checkbox {
    margin-right: 25px;
  }
  > p {
    font-size: 30px;
    color: #b2b2b2;
    > span {
      color: #dc1323;
    }
  }
}

.registe-btn {
  background: #ff0000;
  border: none;
}
.registe-info {
  position: relative;
}
.otc-btn {
  position: absolute;
  top: 22px;
  right: 30px;
  background-color: #fff;
  padding-left: 10px;
  color: rgb(0, 76, 254);
  font-size: 35px;
  font-family: 'PingFang-Medium';
}
.kong {
  height: 100px;
}
.new-form {
  width: 100%;
  height: calc(100% - 100px);
  background-color: #fff;
  .van-count-down {
    font-size: 30px;
  }
  > .form-item {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
    > span {
      font-size: 36px;
      color: #323233;
      margin-bottom: 15px;
    }
    > input {
      // width: calc(100% - 400px);
      font-size: 36px;
      color: #323233;
      border: 0;
    }
    p {
      font-size: 30px;
      color: #555555;
    }
    > .send-code-com {
      position: absolute;
      right: 30px;
      bottom: 32px;
    }
  }
  > .form-item:nth-child(1) {
    padding-top: 50px;
    > input {
      padding-left: 70px;
    }
    > .num-absolute {
      position: absolute;
      font-size: 36px;
      color: #323233;
      bottom: 32px;
    }
  }
  > .form-item:nth-child(2) {
    > input {
      width: 440px;
    }
  }
  > .btn-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    > button {
      width: 40%;
      height: 100px;
      border-radius: 6px;
      border: 0;
      font-size: 32px;
      color: #fff;
      background-color: #3eb4ff;
    }
    > button:nth-last-child(1) {
      background-color: #dc1323;
      margin-left: 50px;
    }
  }
}
</style>
